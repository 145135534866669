import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

function Arrow(props) {
  const { up, down, left, right, ...moreProps } = props

  return (
    <button
      {...moreProps}
      className={classnames(styles.arrowContainer, props.className, {
        [styles.up]: up,
        [styles.down]: down,
        [styles.left]: left,
        [styles.right]: right,
      })}
    >
      <hr />
      <hr />
    </button>
  )
}

export default Arrow
