import React from 'react'
import PropTypes from 'prop-types'

import Nav from './Nav'
import Footer from './Footer'
import './layout.css'
import '../fonts/stylesheet.css'

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
