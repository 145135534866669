import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import heroMp4 from '../video/hero-muted.mp4'
import heroWebm from '../video/hero-muted.webm'
import videoPost from '../images/video-poster.jpg'
import heroMp4Movil from '../video/heromovil.mp4'
import styles from './index.module.css'

function ThankyouPageSantafe() {
  let isVideoDesk
  if (typeof screen !== 'undefined') {
    if (screen.width > 640) {
      isVideoDesk = false
    } else {
      isVideoDesk = true
    }
  }
  return (
    <Layout>
      <SEO title="Downtown by Be Grand" main />
      <div className={styles.hero}>
        <video width="100%" muted loop playsInline autoPlay>
          {isVideoDesk == false && <source src={heroMp4} type="video/mp4" />}
          {isVideoDesk == true && (
            <source src={heroMp4Movil} type="video/mp4" />
          )}
          {`Sorry, your browser doesn't support embedded videos.`}
        </video>
        <div className={styles.heroContent}>
          <h1>¡Gracias por tu interés!</h1>
          <p>
            En breve uno de nuestros asesores se pondrá en contacto contigo.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ThankyouPageSantafe
