/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

import grand from '../../images/grand-footer.png'
import downtown from '../../images/downtown-footer.png'
import Arrow from '../Arrow'

import styles from './styles.module.css'

function Footer() {
  function handleArrowClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.logos}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://oficinasdowntown.mx/"
        >
          <img src={downtown} alt="Downtown" />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://begrand.mx/">
          <img src={grand} alt="BeGrand" />
        </a>
      </div>
      <Arrow onClick={handleArrowClick} className={styles.arrow} up />
      <div className={styles.footerContent}>
        <ul className={styles.siteMap}>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://begrand.mx/responsabilidad-corporativa"
            >
              Responsabilidad corporativa
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://begrand.mx/inversionistas"
            >
              Inversionistas
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://begrand.mx/gobierno-corporativo"
            >
              Gobierno corporativo
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://begrand.mx/bolsa-de-trabajo"
            >
              Bolsa de trabajo
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/begrand-politicas-privacidad.pdf"
            >
              Aviso de privacidad
            </a>
          </li>
          <li>
            <ul className={styles.socialList}>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.facebookIcon}
                  href="https://facebook.com/begrand/"
                />
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.instagramIcon}
                  href="https://www.instagram.com/begrand/?hl=en"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className={styles.copyright}>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://concepthaus.mx"
          >
            Powered by Concepthaus
          </a>
        </p>
        <p>© WorkSweetWork 2019</p>
      </div>
    </footer>
  )
}

export default Footer
